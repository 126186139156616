import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Image, Modal, Row, Typography, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import iconDate from "../../assets/images/date.png";
import iconEmail from "../../assets/images/iconEmail1.png";
import iconMap from "../../assets/images/iconMap.png";
import iconMic from "../../assets/images/iconMic.png";
import iconUser from "../../assets/images/iconUser.png";
import imageVoid from "../../assets/images/imageVoid.png";
import phoneOutline from "../../assets/images/phoneOutline.png";
import iconTime from "../../assets/images/time.png";
import { onSetFormSearch, onSetRoom } from "../../features/checkRoomSlide";
import { cancelRoom } from "../../services/branch";
import "../../styles/orderResult.css";
const OrderResult = (props) => {
	const { onSetCurrentStep } = props;
	const { room } = useSelector((state) => state.rooms);
	const { branchs } = useSelector((state) => state.branchs);
	const branch = branchs?.find((item) => item._id === room?.branch);
	const dispatch = useDispatch();
	const [isShowModal, setIsShowModal] = useState(false);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const onRemove = async () => {
		setLoading(true);
		const dataCheck = await cancelRoom(room.book_id);
		if (dataCheck?.code === "2000") {
			message.success("Hủy đặt phòng thành công", 3);
			setTimeout(() => {
				setLoading(false);
				dispatch(onSetFormSearch({}));
				dispatch(onSetRoom({}));
				navigate("/");
			}, 1000);
		} else {
			message.error("Hủy đặt phòng thất bại. Vui lòng thử lại sau!", 3);
		}
	};

	useEffect(() => {
		window.scrollTo(0, 0, { behavior: "smooth" });
	}, []);

	const onCloseModal = () => {
		setIsShowModal(false);
	};

	const onShowModal = () => {
		setIsShowModal(true);
	};

	const onBack = () => {
		onSetCurrentStep(0);
	};

	const start_time = new Date(room?.start_time * 1000);
	const end_time = new Date(room?.end_time * 1000);
	return (
		<div>
			<Modal
				title=""
				open={isShowModal}
				footer={null}
				closable={false}
				style={{ padding: 0 }}
			>
				<div className="d-flex align-items-center justify-content-end">
					<div onClick={onCloseModal} className="close-icon">
						<CloseOutlined />
					</div>
				</div>
				<div
					className="text-center"
					style={{ marginLeft: 64, marginRight: 64 }}
				>
					<Typography.Title level={3}>Huỷ đặt phòng</Typography.Title>
					<p className="text-center text-remove-order">
						Bạn có muốn hủy đặt phòng này không?
					</p>
					<div className="d-flex align-items-center justify-content-center">
						<Button onClick={onCloseModal} className="btn-close-cancle">
							Không
						</Button>
						<Button
							loading={loading}
							onClick={onRemove}
							className="btn-close-success"
						>
							Đồng ý
						</Button>
					</div>
				</div>
			</Modal>
			<div className="d-flex align-items-center justify-content-center">
				<div className="icon-position-wrapper">
					<Image preview={false} src={imageVoid} />
					<div
						className="d-flex align-items-center justify-content-center icon-position-item"
						style={{
							width: 30,
							height: 30,
							backgroundColor: "#1FB266",
							borderRadius: "100%",
						}}
					>
						<CheckOutlined
							style={{
								color: "white",
							}}
						/>
					</div>
				</div>
			</div>
			<div className="text-center" style={{ marginTop: 32 }}>
				<Typography.Title level={5} className="m-0 text-info">
					Bạn đã đặt phòng thành công!
				</Typography.Title>
				<p className="text-info ms-0">
					Đơn đặt phòng của bạn đã được ghi nhận thành công, chúng tôi sẽ gọi lại cho bạn để xác nhận đơn!
				</p>
			</div>
			<div className="box-wrapper">
				<Row className="mb-2">
					<Col span={12}>
						<Typography.Title className="mt-0 text-header-infor" level={4}>
							Thông tin đặt phòng
						</Typography.Title>
						<div
							className="d-flex align-items-center"
							style={{ marginBottom: 16 }}
						>
							<Image className="image-icon" preview={false} src={iconMap} />
							{branch && branch?.name && (
								<Link
									to={branch?.google_map}
									target="_blank"
									className="text-link-mobile"
								>
									<Typography.Text className="text-info">
										{branch?.name} - {branch?.address}
									</Typography.Text>
								</Link>
							)}
						</div>
						<div
							className="d-flex align-items-center"
							style={{ marginBottom: 16 }}
						>
							<Image
								className="image-icon"
								preview={false}
								src={phoneOutline}
							/>
							<Typography.Text className="text-info">
								Điện thoại liên hệ: {branch?.phone}
							</Typography.Text>
						</div>
						<div
							className="d-flex align-items-center"
							style={{ marginBottom: 16 }}
						>
							<Image className="image-icon" preview={false} src={iconMic} />
							<Typography.Text className="text-info">
								{room?.room === "small" &&
									`S Box (từ ${branch[room?.room]?.capacity} người) - ${
										start_time.getHours() >= 18 ? "Sau 18h" : "Trước 18h"
									}`}
								{room?.room === "medium" &&
									`M Box (từ ${
										branch[room?.room]?.capacity
									} người) - ${
										start_time.getHours() >= 18 ? "Sau 18h" : "Trước 18h"
									}`}
								{room?.room === "large" &&
									`L Box (trên ${
										branch[room?.room]?.capacity
									} người) - ${
										start_time.getHours() >= 18 ? "Sau 18h" : "Trước 18h"
									}`}
							</Typography.Text>
						</div>
						<div
							className="d-flex align-items-center"
							style={{ marginBottom: 16 }}
						>
							<Image className="image-icon" preview={false} src={iconDate} />
							<Typography.Text className="d-flex align-items-center text-info">
								{moment(start_time).format("DD/MM")} -{" "}
								<Image className="image-icon" preview={false} src={iconTime} />
								Từ {moment(start_time).format("HH:mm")} - Đến{" "}
								{moment(end_time).format("HH:mm")}
							</Typography.Text>
						</div>
					</Col>
					<Col span={12}>
						<Typography.Title level={4} className="text-header-infor mt-0">
							Thông tin liên lạc
						</Typography.Title>
						<div
							className="d-flex align-items-center"
							style={{ marginBottom: 16 }}
						>
							<Image className="image-icon" preview={false} src={iconUser} />
							<Typography.Text className="text-info">
								{room?.name}
							</Typography.Text>
						</div>
						<div
							className="d-flex align-items-center"
							style={{ marginBottom: 16 }}
						>
							<Image className="image-icon" preview={false} src={iconEmail} />
							<Typography.Text className="text-info">
								{room?.email ?? "N/A"}
							</Typography.Text>
						</div>
						<div
							className="d-flex align-items-center"
							style={{ marginBottom: 16 }}
						>
							<Image
								className="image-icon"
								preview={false}
								src={phoneOutline}
							/>
							<Typography.Text className="text-info">
								{room?.mobile}
							</Typography.Text>
						</div>
					</Col>
				</Row>

				<div className="d-flex justify-content-center mt-2 pt-2 border-ourline">
					<Button
						onClick={onShowModal}
						htmlType="button"
						className="btn-search-order me-1"
					>
						Huỷ đặt phòng
					</Button>
					<Button
						onClick={onBack}
						htmlType="button"
						className="btn-search ms-1"
					>
						Về trang chủ
					</Button>
				</div>
			</div>
		</div>
	);
};

OrderResult.propTypes = {};

export default OrderResult;
