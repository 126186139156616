import chualang from "../images/chualang.png";
import kinhte from "../images/kinhte.png";
import vanquan from "../images/vanquan.png";
import namdong from "../images/namdong.png";
import nongnghiep from "../images/nongnghiep.png";
import taichinh from "../images/taichinh.png";
import caugiay from "../images/caugiay.png";

export const times = [
	{
		label: "11:00",
		value: "11:00",
		key: 11,
	},
	{
		label: "11:30",
		value: "11:30",
		key: 11.5,
	},
	{
		label: "12:00",
		value: "12:00",
		key: 12,
	},
	{
		label: "12:30",
		value: "12:30",
		key: 12.5,
	},
	{
		label: "13:00",
		value: "13:00",
		key: 13,
	},
	{
		label: "13:30",
		value: "13:30",
		key: 13.5,
	},
	{
		label: "14:00",
		value: "14:00",
		key: 14,
	},
	{
		label: "14:30",
		value: "14:30",
		key: 14.5,
	},
	{
		label: "15:00",
		value: "15:00",
		key: 15,
	},
	{
		label: "15:30",
		value: "15:30",
		key: 15.5,
	},
	{
		label: "16:00",
		value: "16:00",
		key: 16,
	},
	{
		label: "16:30",
		value: "16:30",
		key: 16.5,
	},
	{
		label: "17:00",
		value: "17:00",
		key: 17,
	},
	{
		label: "17:30",
		value: "17:30",
		key: 17.5,
	},
	{
		label: "18:00",
		value: "18:00",
		key: 18,
	},
	{
		label: "18:30",
		value: "18:30",
		key: 18.5,
	},
	{
		label: "19:00",
		value: "19:00",
		key: 19,
	},
	{
		label: "19:30",
		value: "19:30",
		key: 19.5,
	},
	{
		label: "20:00",
		value: "20:00",
		key: 20,
	},
	{
		label: "20:30",
		value: "20:30",
		key: 20.5,
	},
	{
		label: "21:00",
		value: "21:00",
		key: 21,
	},
	{
		label: "21:30",
		value: "21:30",
		key: 21.5,
	},
	{
		label: "22:00",
		value: "22:00",
		key: 22,
	},
	{
		label: "22:30",
		value: "22:30",
		key: 22.5,
	},
	{
		label: "23:00",
		value: "23:00",
		key: 23,
	},
	{
		label: "23:30",
		value: "23:30",
		key: 23.5,
	},
];

export const hours = [
	{
		label: "11 giờ 30 phút",
		value: "30",
	},
	{
		label: "12 giờ",
		value: "60",
	},
	{
		label: "12 giờ 30 phút",
		value: "90",
	},
	{
		label: "13 giờ",
		value: "120",
	},
	{
		label: "13 giờ 30 phút",
		value: "150",
	},
	{
		label: "14 giờ",
		value: "180",
	},
	{
		label: "14 giờ 30 phút",
		value: "210",
	},
	{
		label: "15 giờ",
		value: "240",
	},
	{
		label: "15 giờ 30 phút",
		value: "270",
	},
	{
		label: "16 giờ",
		value: "300",
	},
	{
		label: "17 giờ 30 phút",
		value: "330",
	},
	{
		label: "18 giờ",
		value: "360",
	},
	{
		label: "18 giờ 30 phút",
		value: "390",
	},
	{
		label: "19 giờ",
		value: "420",
	},
	{
		label: "19 giờ 30 phút",
		value: "450",
	},
	{
		label: "20 giờ",
		value: "480",
	},
	{
		label: "20 giờ 30 phút",
		value: "510",
	},
	{
		label: "21 giờ",
		value: "540",
	},
	{
		label: "21 giờ 30 phút",
		value: "570",
	},
	{
		label: "22 giờ",
		value: "600",
	},
	{
		label: "22 giờ 30 phút",
		value: "630",
	},
	{
		label: "23 giờ",
		value: "660",
	},
	{
		label: "23 giờ 30 phút",
		value: "690",
	},
	{
		label: "24 giờ",
		value: "720",
	},
];

export const minutes = [
	{
		label: "30 phút",
		value: "30",
	},
	{
		label: "60 phút",
		value: "60",
	},
	{
		label: "90 phút",
		value: "90",
	},
	{
		label: "120 phút",
		value: "120",
	},
	{
		label: "150 phút",
		value: "150",
	},
	{
		label: "180 phút",
		value: "180",
	},
	// {
	//   label: "210 phút",
	//   value: "210",
	// },
	// {
	//   label: "240 phút",
	//   value: "240",
	// },
	// {
	//   label: "270 phút",
	//   value: "270",
	// },
	// {
	//   label: "300 phút",
	//   value: "300",
	// },
	// {
	//   label: "330 phút",
	//   value: "330",
	// },
	// {
	//   label: "360 phút",
	//   value: "360",
	// },
	// {
	//   label: "390 phút",
	//   value: "390",
	// },
	// {
	//   label: "420 phút",
	//   value: "420",
	// },
	// {
	//   label: "450 phút",
	//   value: "450",
	// },
	// {
	//   label: "480 phút",
	//   value: "480",
	// },
	// {
	//   label: "510 phút",
	//   value: "510",
	// },
	// {
	//   label: "540 phút",
	//   value: "540",
	// },
	// {
	//   label: "570 phút",
	//   value: "570",
	// },
	// {
	//   label: "600 phút",
	//   value: "600",
	// },
	// {
	//   label: "630 phút",
	//   value: "630",
	// },
	// {
	//   label: "660 phút",
	//   value: "660",
	// },
	// {
	//   label: "690 phút",
	//   value: "690",
	// },
	// {
	//   label: "720 phút",
	//   value: "720",
	// }
];

export const rooms = [
	{
		value: 1,
		label: "S Box (1-2 người)",
		time: "Trước 18h: 60.000VND/h",
	},
	{
		value: 2,
		label: "M Box (3-7 người)",
		time: "Trước 18h: 60.000VND/h",
	},
	{
		value: 3,
		label: "S Box (1-2 người)",
		time: "Sau 18h: 90.000VND/h",
	},
	{
		value: 4,
		label: "L Box (trên 5 người)",
		time: "Sau 18h: 120.000VND/h",
	},
	{
		value: 4,
		label: "L Box (trên 5 người)",
		time: "Sau 18h: 120.000VND/h",
	},
];

export const units = [
	{
		label: "Cơ sở Kinh tế: 0399190355",
		value: 1,
		addressName: "96 Ngõ Tự Do",
		address: "ngõ 213 Trần Đại Nghĩa",
	},
	{
		label: "Cơ sở Văn Quán: 0961521312",
		value: 2,
		addressName: "",
		address: "40 TT11A Văn Quán",
	},
	{
		label: "Cơ sở Kinh tế: 0399190355",
		value: 1,
		addressName: "96 Ngõ Tự Do",
		address: "ngõ 213 Trần Đại Nghĩa",
	},
	{
		label: "Cơ sở Văn Quán: 0961521312",
		value: 2,
		addressName: "",
		address: "40 TT11A Văn Quán",
	},
	{
		label: "Cơ sở Kinh tế: 0399190355",
		value: 1,
		addressName: "96 Ngõ Tự Do",
		address: "ngõ 213 Trần Đại Nghĩa",
	},
	{
		label: "Cơ sở Văn Quán: 0961521312",
		value: 2,
		addressName: "",
		address: "40 TT11A Văn Quán",
	},
];
export const restrictNumber = (event) => {
	const regex = new RegExp("^[0-9]+$");
	const key = String.fromCharCode(
		!event.charCode ? event.which : event.charCode,
	);
	if (!regex.test(key)) {
		event.preventDefault();
		return false;
	}
};

export const moneyFormat = (number) => {
	// Chuyển đổi số thành chuỗi và loại bỏ tất cả các ký tự không phải số
	const integerPart = `${parseInt(number, 10)}`;

	// Sử dụng regex để thêm dấu chấm ngăn cách các hàng đơn vị
	return integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};

export const errorCode = {
	2000: "Thành công",
	1000: "Lỗi",
	1002: "Token không hợp lệ hoặc hết hạn, yêu cầu quay về trang đăng nhập",
	1004: "Tham số không hợp lệ",
	2101: "Không còn phòng, hiển thị danh sách gợi ý",
	2102: "Không còn phòng",
	1007: "Không còn phòng trống",
	10044: "Tham số các trường dữ liệu về thời gian không hợp lệ",
	1009: "Cần có tham số captcha",
	1010: "Sai captcha",
};

export function formatDateISOString(date) {
	return new Date(
		date.valueOf() - new Date(date).getTimezoneOffset() * 60 * 1000,
	);
}

export const dataBanner = {
	title_one: "Giải phóng âu lo",
	title_two: "Tự do thể hiện",
	contents: [
		{
			id: 1,
			content:
				"Chuỗi phòng thu âm, giải trí hàng đầu tại Việt Nam theo phong cách Hàn Quốc",
		},
		{
			id: 2,
			content:
				"Mang lại niềm vui, dành cho mọi lứa tuổi chung đam mê ca hát và âm nhạc",
		},
	],
};

export const dataShops = [
	{
		id: 1,
		name: "Cơ sở chùa láng",
		address: "Tầng 2, số 2 nhà 32 Nam Đồng",
		branchId: "",
		image: chualang,
	},
	{
		id: 2,
		name: "Cơ sở nam đồng",
		address: "Tầng 2, số 2 nhà 32 Nam Đồng",
		branchId: "",
		image: namdong,
	},
	{
		id: 3,
		name: "Cơ sở kinh tế",
		address: "Tầng 2, số 2 nhà 32 Nam Đồng",
		branchId: "",
		image: kinhte,
	},
	{
		id: 4,
		name: "Cơ sở cầu giấy",
		address: "Tầng 2, số 2 nhà 32 Nam Đồng",
		branchId: "",
		image: caugiay,
	},
	{
		id: 5,
		name: "Cơ sở tài chính",
		address: "Tầng 2, số 2 nhà 32 Nam Đồng",
		branchId: "",
		image: taichinh,
	},
	{
		id: 6,
		name: "Cơ sở nông nghiệp",
		address: "Tầng 2, số 2 nhà 32 Nam Đồng",
		branchId: nongnghiep,
		image: nongnghiep,
	},
	{
		id: 7,
		name: "Cơ sở nam đồng",
		address: "Tầng 2, số 2 nhà 32 Nam Đồng",
		branchId: "",
		image: namdong,
	},
	{
		id: 8,
		name: "Cơ sở văn quán",
		address: "Tầng 2, số 2 nhà 32 Nam Đồng",
		branchId: "",
		image: vanquan,
	},
];

